import { Stage } from './stage';

if (!Date.now)
  Date.now = () => {
    return new Date().getTime();
  };
(() => {
  const vendors = ['webkit', 'moz'];
  for (let i = 0; i < vendors.length && !window.requestAnimationFrame; ++i) {
    const vp = vendors[i];
    window.requestAnimationFrame = window[`${vp}RequestAnimationFrame`];
    window.cancelAnimationFrame = window[`${vp}CancelAnimationFrame`] || window[`${vp}CancelRequestAnimationFrame`];
  }
  if (
    /iP(ad|hone|od).*OS 6/.test(window.navigator.userAgent) || // iOS6 is buggy
    !window.requestAnimationFrame ||
    !window.cancelAnimationFrame
  ) {
    let lastTime = 0;
    window.requestAnimationFrame = (callback) => {
      const now = Date.now();
      const nextTime = Math.max(lastTime + 16, now);
      return setTimeout(() => {
        callback((lastTime = nextTime));
      }, nextTime - now);
    };
    window.cancelAnimationFrame = clearTimeout;
  }
})();

class Game {
  constructor(id, params) {
    const setting = {
      width: 960,
      height: 640,
      stages: [],
      events: [],
      index: 0,
      hander: '',
    };
    Object.assign(this, setting, params);
    const canvas = document.getElementById(id);
    canvas.width = this.width;
    canvas.height = this.height;
    this.canvas = canvas;
    this.context = canvas.getContext('2d');
  }

  start() {
    let f = 0;
    const fn = () => {
      const stage = this.stages[this.index];
      this.context.clearRect(0, 0, this.width, this.height);
      this.context.fillStyle = '#000000';
      this.context.fillRect(0, 0, this.width, this.height);
      f++;
      if (stage.timeout) {
        stage.timeout--;
      }
      if (stage.update() !== false) {
        stage.maps.forEach((map) => {
          if (!(f % map.frames)) {
            map.times = f / map.frames;
          }
          if (map.cache) {
            if (!map.imageData) {
              this.context.save();
              map.draw(this.context);
              map.imageData = this.context.getImageData(0, 0, this.width, this.height);
              this.context.restore();
            } else {
              this.context.putImageData(map.imageData, 0, 0);
            }
          } else {
            map.update();
            map.draw(this.context);
          }
        });
        stage.items.forEach((item) => {
          if (!(f % item.frames)) {
            item.times = f / item.frames;
          }
          if (stage.status === 1 && item.status !== 2) {
            if (item.location) {
              item.coord = item.location.position2coord(item.x, item.y);
            }
            if (item.timeout) {
              item.timeout--;
            }
            item.update();
          }
          item.draw(this.context);
        });
      }
      this.hander = requestAnimationFrame(fn);
    };
    this.hander = requestAnimationFrame(fn);
  }

  stop() {
    if (this.hander !== '') cancelAnimationFrame(this.hander);
  }

  getPosition(e) {
    const box = this.canvas.getBoundingClientRect();
    return {
      x: e.clientX - box.left * (this.width / box.width),
      y: e.clientY - box.top * (this.height / box.height),
    };
  }

  createStage(options, game, page) {
    const stage = new Stage(options, game, page);
    stage.index = this.stages.length;
    this.stages.push(stage);
    return stage;
  }

  setStage(index) {
    this.stages[this.index].status = 0;
    this.index = index;
    this.stages[this.index].status = 1;
    this.stages[this.index].reset();
    return this.stages[this.index];
  }

  nextStage() {
    if (this.index < this.stages.length - 1) {
      return this.setStage(++this.index);
    }
    throw new Error('unfound new stage.');
  }

  getStages() {
    return this.stages;
  }

  init() {
    this.index = 0;
    this.start();
  }
}

export { Game };
