import { Item } from './item';
import { Map } from './map';

class Stage {
  constructor(params, game, page) {
    this.game = game;
    this.page = page;
    this.params = params || {};
    this.settings = {
      index: 0,
      status: 0,
      maps: [],
      audio: [],
      images: [],
      items: [],
      timeout: 0,
      update() {},
    };
    Object.assign(this, this.settings, this.params);
  }

  createItem(options, game, page) {
    const item = new Item(options, game, page);
    if (item.location) {
      Object.assign(item, item.location.coord2position(item.coord.x, item.coord.y));
    }
    item.stage = this;
    item.id = this.items.length;
    this.items.push(item);
    return item;
  }

  resetItems() {
    this.status = 1;
    this.items.forEach((item) => {
      Object.assign(item, item.settings, item.params);
      if (item.location) {
        Object.assign(item, item.location.coord2position(item.coord.x, item.coord.y));
      }
    });
  }

  getItemsByType(type) {
    return this.items.filter((item) => {
      return item.type === type;
    });
  }

  createMap(options, game, page) {
    const map = new Map(options, game, page);
    map.data = JSON.parse(JSON.stringify(map.params.data));
    map.yLength = map.data.length;
    map.xLength = map.data[0].length;
    map.imageData = null;
    map.stage = this;
    map.id = this.maps.length;
    this.maps.push(map);
    return map;
  }

  resetMaps() {
    this.status = 1;
    this.maps.forEach((map) => {
      Object.assign(map, map.settings, map.params);
      map.data = JSON.parse(JSON.stringify(map.params.data));
      map.yLength = map.data.length;
      map.xLength = map.data[0].length;
      map.imageData = null;
    });
  }

  reset() {
    Object.assign(this, this.settings, this.params);
    this.resetItems();
    this.resetMaps();
  }

  bind(eventType, callback) {
    if (!this.game.events[eventType]) {
      this.game.events[eventType] = {};
      window.addEventListener(eventType, (e) => {
        const key = `s${this.game.index}`;
        if (this.game.events[eventType][key]) {
          this.game.events[eventType][key](e);
        }
      });
    }
    this.game.events[eventType][`s${this.index}`] = callback.bind(this);
  }
}
export { Stage };
