<template>
  <a-layout-content>
    <div class="page">
      <slot />
    </div>
  </a-layout-content>
</template>

<script>
export default {
  name: 'ContentMain',
};
</script>

<style lang="less" scoped>
.page {
  background: #ffffff;
}
</style>
