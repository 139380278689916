<template>
  <a-layout-header style="background: #fff">
    <a-row>
      <a-col :span="2">
        <div class="logo">
          <a href="https://br24.com" target="_blank" title="Br24 Website">
            <img class="img-logo" src="/image/logo/Br24_LogoSize130x90.svg" width="130" height="90" />
          </a>
        </div>
      </a-col>
      <a-col :span="2" :offset="20" align="right">
        <MultiLanguage />
      </a-col>
    </a-row>
  </a-layout-header>
  <Banner v-if="$route.name !== 'Game'" />
</template>

<script>
import MultiLanguage from '../common/MultiLanguage';
import Banner from '@/components/layout/Banner';

export default {
  name: 'HeaderMain',
  components: { Banner, MultiLanguage },
  data() {
    return {
      customerLogo: '/image/logo/br24_logo_blue_50px.png',
    };
  },
};
</script>

<style lang="less" scoped>
.logo {
  text-align: center;
  padding: 15px 0;
}
.img-logo {
  width: 130px;
  height: 90px;
}
.ant-layout-header {
  height: 120px;
}
</style>
