const auth = {
  namespaced: true,
  state() {
    const states = {
      userId: '',
      confirmLoading: false,
    };
    if (localStorage.length > 0) {
      const keys = Object.keys(states);
      keys.map((key) => {
        const value = localStorage.getItem(key);
        if (value) states[key] = value;
      });
    }
    return states;
  },
  mutations: {
    saveUserId(state, userId) {
      state.userId = userId;
    },
    deleteToken(state) {
      state.userId = '';
    },
  },
  actions: {
    async saveUserId({ commit }, userId) {
      await localStorage.setItem('userId', userId);
      commit('saveUserId', userId);
    },
    async deleteUserId({ commit }) {
      await localStorage.setItem('userId', '');
      commit('deleteToken');
    },
  },
  getters: {
    getUserId(state) {
      return state.userId || '';
    },
  },
};
export { auth };
