import locales from '@/locales';

const defaultLanguage = (() => {
  const availableLocales = Object.keys(locales) || [];
  const localStorageLanguage = localStorage.getItem('lang');
  const browserLanguage = navigator.language.slice(0, 2);
  if (localStorageLanguage && availableLocales.includes(localStorageLanguage)) return localStorageLanguage;
  if (browserLanguage && availableLocales.includes(browserLanguage)) return browserLanguage;
  return 'en';
})();
const app = {
  namespaced: true,
  state() {
    return {
      language: defaultLanguage,
    };
  },
  mutations: {
    setLanguage(state, { language }) {
      state.language = language;
    },
  },
  actions: {
    async updateLanguage({ commit }, { language }) {
      commit('setLanguage', { language });
    },
  },
  getters: {
    getLanguage(state) {
      return state.language || 'en';
    },
  },
};

export { app };
