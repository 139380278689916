<template>
  <a-row>
    <a-col class="banner-row" :span="24">
      <img class="banner" src="../../../public/image/banner.png" />
      <h1 class="title">
        <span class="outline">
          <span class="title-box">{{ $t('banner.title') }}</span>
        </span>
      </h1>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Banner',
};
</script>

<style lang="less" scoped>
h1 {
  font-size: 30px;
  font-weight: bold;
}
.banner-row {
  text-align: center;
}
.banner {
  width: 100%;
}
.title {
  position: absolute;
  top: 40%;
  width: 100%;
}
.outline {
  border: 1px solid #fff;
  padding: 15px 5px;
}
.title-box {
  background-color: #fff;
  padding: 10px 20px;
}
</style>
