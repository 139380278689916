<template>
  <div class="footer" align="center">
    <div class="content">
      <a-row>
        <a-col :span="12" class="left">
          <a href="https://br24.com" target="_blank" title="Br24 Website">
            <img src="/image/logo/br24_logo_blue_150px.png" />
          </a>
          <div class="info">
            <div>{{ $t('phone') }}: <a href="tel:+49 2561 97903-0">+49 2561 97903-0</a></div>
            <div>{{ $t('email') }}: <a href="mailto:info@br24.com">info@br24.com</a></div>
            <div>
              <a :href="$t('imprintLink')" target="_blank">{{ $t('imprint') }}</a> |
              <a :href="$t('privacyPolicyLink')" target="_blank">{{ $t('privacyPolicy') }}</a>
            </div>
          </div>
        </a-col>
        <a-col :span="12" class="right">
          <a href="https://www.facebook.com/Bildretusche24/?ref=aymt_homepage_panel" target="_blank">
            <img class="social" src="/image/facebook.png" />
          </a>
          <a href="https://www.linkedin.com/company/13223321" target="_blank">
            <img class="social" src="/image/linkedin.png" />
          </a>
          <a href="https://www.xing.com/pages/bildretusche24gmbh" target="_blank">
            <img class="social" src="/image/xing.png" />
          </a>
        </a-col>
      </a-row>
    </div>
  </div>
  <div class="end-footer" align="center">
    <div class="content">© {{ new Date().getFullYear() }} BR24 Vietnam Ltd.</div>
  </div>
</template>

<script>
export default {
  name: 'FooterMain',
};
</script>
<style lang="less" scoped>
.content {
  padding: 0 66px;
}
.footer .content .left {
  text-align: left;
  float: left;
  padding: 35px 0;
  display: inherit;
}
.footer .content .right {
  text-align: right;
  float: right;
  padding: 35px 0;
}
.left .info {
  padding: 10px 0 0 30px;
  font-size: 14px;
}
.end-footer {
  background-color: #fff;
}
.end-footer .content {
  text-align: left;
  font-size: 14px;
}
.social {
  width: 50px;
  padding-right: 15px;
  margin-top: 35px;
}
</style>
