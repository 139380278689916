<template>
  <div id="app">
    <main-layouts>
      <router-view />
    </main-layouts>
  </div>
</template>
<script>
import MainLayouts from '@/layouts/Main';

export default {
  name: 'App',
  components: {
    MainLayouts,
  },
};
</script>
<style lang="less" scoped>
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-size: 22px;
  color: #100551;
  font-weight: bold;
}
h1 {
  size: 30px;
}
</style>
