<template>
  <div class="board">
    <h2>{{ $t('gameIntroduction.introductionTitle') }}:</h2>
    <div class="sub-title">
      {{ $t('gameIntroduction.subTitle') }}
      <b> {{ $t('gameIntroduction.onlyOnce') }} </b>.
    </div>
    <ul>
      <li>{{ $t('gameIntroduction.introduction1') }}</li>
      <li>{{ $t('gameIntroduction.introduction2') }}</li>
      <li>{{ $t('gameIntroduction.introduction3') }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GameIntroduction',
};
</script>

<style lang="less" scoped>
.board {
  margin-left: 20px;
}
h2 {
  font-size: 22px;
}
ul {
  list-style-type: circle;
}
</style>
