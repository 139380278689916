import { createApp } from 'vue';
import { RECAPTCHA_ACCESS_KEY_ID } from '../config';
import Antd from 'ant-design-vue';
import App from './App.vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { router } from './router';
import { store } from './store';
import { i18n } from './i18n';
import 'ant-design-vue/dist/antd.less';

const app = createApp(App);
app.use(VueReCaptcha, {
  siteKey: RECAPTCHA_ACCESS_KEY_ID,
  loaderOptions: {
    useRecaptchaNet: true,
  },
});

app.use(Antd);
app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');
