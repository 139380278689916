class Map {
  constructor(params, game, page) {
    this.game = game;
    this.page = page;
    this.params = params || {};
    this.id = 0;
    this.stage = null;
    this.settings = {
      x: 0,
      y: 0,
      size: 20,
      data: [],
      xLength: 0,
      yLength: 0,
      frames: 1,
      times: 0,
      cache: false,
      update() {},
      draw() {},
    };
    Object.assign(this, this.settings, this.params);
  }

  get(x, y) {
    if (this.data[y] && typeof this.data[y][x] !== 'undefined') {
      return this.data[y][x];
    }
    return -1;
  }

  set(x, y, value) {
    if (this.data[y]) {
      this.data[y][x] = value;
    }
  }

  coord2position(cx, cy) {
    return {
      x: this.x + cx * this.size + this.size / 2,
      y: this.y + cy * this.size + this.size / 2,
    };
  }

  position2coord(x, y) {
    const fx = (Math.abs(x - this.x) % this.size) - this.size / 2;
    const fy = (Math.abs(y - this.y) % this.size) - this.size / 2;
    return {
      x: Math.floor((x - this.x) / this.size),
      y: Math.floor((y - this.y) / this.size),
      offset: Math.sqrt(fx * fx + fy * fy),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  finder(params) {
    const defaults = {
      map: null,
      start: {},
      end: {},
      type: 'path',
    };
    const options = { ...defaults, ...params };
    if (options.map[options.start.y][options.start.x] || options.map[options.end.y][options.end.x]) {
      return [];
    }
    let finded = false;
    const result = [];
    const yLength = options.map.length;
    const xLength = options.map[0].length;
    const steps = [];
    for (let y = yLength; y--; ) {
      steps[y] = new Array(xLength).fill(0);
    }
    const getValue = (x, y) => {
      if (options.map[y] && typeof options.map[y][x] !== 'undefined') {
        return options.map[y][x];
      }
      return -1;
    };
    const nextDefault = (to) => {
      const value = getValue(to.x, to.y);
      if (value < 1) {
        if (value === -1) {
          to.x = (to.x + xLength) % xLength;
          to.y = (to.y + yLength) % yLength;
          to.change = 1;
        }
        if (!steps[to.y][to.x]) {
          result.push(to);
        }
      }
    };
    const render = (list) => {
      const newList = [];
      const next = (from, to) => {
        const value = getValue(to.x, to.y);
        if (value < 1) {
          if (value === -1) {
            to.x = (to.x + xLength) % xLength;
            to.y = (to.y + yLength) % yLength;
            to.change = 1;
          }
          if (to.x === options.end.x && to.y === options.end.y) {
            steps[to.y][to.x] = from;
            finded = true;
          } else if (!steps[to.y][to.x]) {
            steps[to.y][to.x] = from;
            newList.push(to);
          }
        }
      };
      list.forEach((current) => {
        next(current, { y: current.y + 1, x: current.x });
        next(current, { y: current.y, x: current.x + 1 });
        next(current, { y: current.y - 1, x: current.x });
        next(current, { y: current.y, x: current.x - 1 });
      });
      if (!finded && newList.length) {
        render(newList);
      }
    };
    render([options.start]);
    if (finded) {
      let current = options.end;
      if (options.type === 'path') {
        while (current.x !== options.start.x || current.y !== options.start.y) {
          result.unshift(current);
          current = steps[current.y][current.x];
        }
      } else if (options.type === 'next') {
        nextDefault({ x: current.x + 1, y: current.y });
        nextDefault({ x: current.x, y: current.y + 1 });
        nextDefault({ x: current.x - 1, y: current.y });
        nextDefault({ x: current.x, y: current.y - 1 });
      }
    }
    return result;
  }
}
export { Map };
