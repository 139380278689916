<template>
  <a-row class="invalid">
    <a-col :span="24" align="center">
      <h2 class="padding-top-12 ma-0">{{ $t('invalid.somethingWrong') }}</h2>
      <p class="padding-top-12 ma-0 padding-bottom-12">
        <i class="text" v-html="$t('invalid.redirect', { count })" />
      </p>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Invalid',
  props: {
    invalid: Boolean,
  },
  async created() {
    this.countInterval = await setInterval(this.decrementSeconds, 1000);
  },
  methods: {
    decrementSeconds() {
      if (this.count > 0) this.count -= 1;
      else {
        clearInterval(this.countInterval);
        window.location.href = 'https://br24.com';
      }
    },
  },
  data() {
    return {
      count: 5,
      countInterval: null,
    };
  },
};
</script>

<style lang="less" scoped>
.text {
  max-width: 750px;
}
.invalid {
  min-height: calc(100vh - 720px);
}
.ma-0 {
  margin: 0px;
}
.padding-top-12 {
  padding-top: 12px;
}
.padding-bottom-12 {
  padding-bottom: 12px;
}
</style>
