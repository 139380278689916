class Item {
  constructor(params, game, page) {
    this.game = game;
    this.page = page;
    this.params = params || {};
    this.id = 0;
    this.stage = null;
    this.settings = {
      x: 0,
      y: 0,
      width: 20,
      height: 20,
      type: 0,
      color: '#F00',
      status: 1,
      orientation: 0,
      speed: 0,
      location: null,
      coord: null,
      path: [],
      vector: null,
      frames: 1,
      times: 0,
      timeout: 0,
      control: {},
      update() {},
      draw() {},
    };
    Object.assign(this, this.settings, this.params);
  }

  bin(eventType, callback) {
    if (!this.game.events[eventType]) {
      this.game.events[eventType] = {};
      this.game.canvas.addEventListener(eventType, (e) => {
        const position = this.game.getPosition(e);
        this.game.stages[this.game.index].items.forEach((item) => {
          if (Math.abs(position.x - item.x) < item.width / 2 && Math.abs(position.y - item.y) < item.height / 2) {
            const key = `s${this.game.index}i${item.id}`;
            if (this.game.events[eventType][key]) {
              this.game.events[eventType][key](e);
            }
          }
        });
        e.preventDefault();
      });
    }
    this.game.events[eventType][`s${this.game.stage.index}i${this.id}`] = callback.bind(this);
  }
}

export { Item };
