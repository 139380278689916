const axios = require('axios').default;
const { message } = require('ant-design-vue');
const { PACMAN_API } = require('@/../config');

const config = {
  baseURL: `${PACMAN_API}`,
  timeout: 30000,
};

const pacmanAPI = axios.create(config);

pacmanAPI.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.code === 'ECONNABORTED') message.error(this.$t('request.timeoutRequest'));
    return Promise.reject(error);
  },
);

export { pacmanAPI };
