<template>
  <a-spin :spinning="spinning" class="spin">
    <Invalid v-if="invalid" :invalid="invalid" />
    <Played v-if="user.hasPlayed" :user="user" />
    <NotPlayedYet v-else-if="user.hasPlayed === false" :id="user.id" />
  </a-spin>
</template>

<script>
import { mapGetters } from 'vuex';
import { getUser } from '@/service/game.servive';
import NotPlayedYet from '@/components/landingPage/NotPlayedYet';
import Played from '@/components/landingPage/Played';
import Invalid from '@/components/landingPage/Invalid';

export default {
  name: 'LandingPage',
  components: { Invalid, Played, NotPlayedYet },
  computed: {
    ...mapGetters('auth', {
      getUserId: 'getUserId',
    }),
  },
  data() {
    return {
      user: {},
      invalid: false,
      spinning: true,
    };
  },
  async created() {
    if (!this.getUserId) this.invalid = true;
    else
      try {
        const { data } = await getUser(this.getUserId);

        this.user = { ...data };
      } catch (e) {
        this.invalid = true;
      }

    this.spinning = false;
  },
};
</script>

<style scoped>
.spin {
  width: 100%;
  padding-top: 10vh;
  min-height: calc(100vh - 720px);
}
</style>
