import { createStore } from 'vuex';
import { app } from './app.stores';
import { auth } from './auth.stores';

const store = createStore({
  modules: {
    auth,
    app,
  },
});

export { store };
