<template>
  <div class="board">
    <div>
      <div class="score">
        <h2>
          {{ $t('resultBoard.scoreTitle') }}
        </h2>
        <h2 class="textScore">
          {{ score }}
        </h2>
      </div>
    </div>
    <div>
      <div class="score">
        <h2>
          {{ $t('resultBoard.lifeTitle') }}
        </h2>
        <h2 class="textScore">
          {{ life }}
        </h2>
      </div>
    </div>
    <div v-if="timeOut < 10 * 60">
      <div class="score">
        <h1>
          <strong>{{ $t('resultBoard.timeTitle') }}</strong>
        </h1>
        <h1 class="textScore">
          <strong>{{ formatTime }}</strong>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultBoard',
  props: {
    score: {
      type: Number,
      default: 0,
      required: true,
    },
    life: {
      type: Number,
      default: 0,
      required: true,
    },
    timeOut: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    formatTime() {
      let minutes = Math.floor(this.timeOut / 60);
      let seconds = this.timeOut - minutes * 60;
      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;
      return `${minutes} : ${seconds}`;
    },
  },
};
</script>

<style lang="less" scoped>
.board {
  margin-left: 20px;
}
.score {
  text-align: center;
  display: inline-block;
}
.textScore {
  color: red;
  text-align: left;
}
h2 {
  font-size: 22px;
}
</style>
