<template>
  <a-dropdown placement="bottomCenter" class="dropbox-mutlti-language">
    <div class="dropdown-label">{{ dropdownLabel }} <down-outlined /></div>
    <template #overlay>
      <a-menu class="menu" v-model="selectMenu" :defaultSelectedKeys="selectMenu" @click="handleClick">
        <a-menu-item v-for="{ code, nativeName } in multiLanguage" :key="code">
          <span> {{ nativeName }} </span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import locales from '@/locales';
import LanguageList from 'iso-639-1';
import { DownOutlined } from '@ant-design/icons-vue';

const multiLanguage = LanguageList.getLanguages(Object.keys(locales));

export default {
  name: 'MultiLanguage',
  components: { DownOutlined },
  computed: {
    selectMenu() {
      return [this.current];
    },
    ...mapGetters('app', {
      getLanguage: 'getLanguage',
    }),
  },
  watch: {
    current(currentLanguage) {
      this.dropdownLabel = LanguageList.getNativeName(currentLanguage);
    },
  },
  data() {
    return {
      multiLanguage,
      current: '',
      dropdownLabel: '',
    };
  },
  created() {
    this.current = this.getLanguage;
  },
  methods: {
    ...mapActions('app', {
      updateLanguage: 'updateLanguage',
    }),
    handleClick({ key }) {
      this.current = key;
      this.updateLanguage({ language: key });
      this.$i18n.locale = key;
      localStorage.setItem('lang', key);
    },
  },
};
</script>

<style lang="less" scoped>
.dropdown-label {
  width: 100px;
  display: inline-block;
  transition: all 1s;
  text-align: left;
}
.menu {
  margin-left: -12px;
}
.dropbox-mutlti-language {
  font-size: 14px;
  margin-top: 28px;
}
</style>
