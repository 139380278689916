import { store } from '@/store';

const checkAuth = async (to, from, next) => {
  const { userId } = store.state.auth;
  const { auth } = to.meta;
  if (auth && !userId) {
    return next({ name: 'LandingPage' });
  }
  return next();
};
export { checkAuth };
