const app = {
  // config app
  TITLE: process.env.VUE_APP_TITLE,
  FAVICON: process.env.VUE_APP_FAVICON,
  LOGO: process.env.VUE_APP_LOGO,
  CUSTOMIZE_THEME_UI: process.env.VUE_APP_CUSTOMIZE_THEME_UI || '',
  MAIN_COLOR_UI: process.env.VUE_APP_MAIN_COLOR_UI || '',
  NODE_ENV: process.env.NODE_ENV,
  PACMAN_API: process.env.VUE_APP_PACMAN_API,
  RECAPTCHA_ACCESS_KEY_ID: process.env.VUE_APP_RECAPTCHA_ACCESS_KEY_ID,
};

const checkEnvironment = (env) => {
  const unsetEnvVars = [];
  const listConfig = Object.keys(app);
  listConfig.map((key) => env[key] === undefined && unsetEnvVars.push(`VUE_APP_${key}`));
  if (unsetEnvVars.length) {
    const error = unsetEnvVars.join(', ');
    throw new Error(`Required environment variables are not set: ${error}`);
  }
};
checkEnvironment(app);
module.exports = app;
