<template>
  <div class="spin">
    <a-spin />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Auth',
  async created() {
    await this.saveUserId(this.$route.params.id);
    await this.$router.push({ name: 'LandingPage' });
  },
  methods: {
    ...mapActions('auth', {
      saveUserId: 'saveUserId',
    }),
  },
};
</script>

<style lang="less" scoped>
.spin {
  text-align: center;
  width: 100%;
  padding-top: 10vh;
  min-height: calc(100vh - 720px);
}
</style>
