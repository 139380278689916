import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/views/LandingPage';
import Auth from '@/views/Auth';
import Game from '@/views/Game';
import { checkAuth } from './checkAuth';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/auth/:id',
    name: 'Auth',
    component: Auth,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
    meta: {
      auth: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: { name: 'LandingPage' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(checkAuth);
export { router };
