<template>
  <a-row justify="center">
    <div class="content padding-top-12">
      <h2 class="ma-0">{{ $t('played.wellDone') }}</h2>
      <p class="padding-top-12 ma-0 center">{{ $t('played.description1') }}</p>
      <h2 class="padding-top-12 ma-0">{{ $t('played.yourPoints', { score: user.score }) }}</h2>
      <p class="padding-top-12 center ma-0">{{ $t('played.givesYouTheChance') }}</p>
      <h2 class="ma-0">{{ $t('played.toWinUp') }}</h2>
      <a-row class="padding-top-12">
        <a-col :span="8" :offset="8" class="space 1st">
          <img src="/image/GoldEgg_250x250_LP.png" alt="GoldEgg_250x250_LP" />
        </a-col>
      </a-row>
      <a-row class="padding-top-5">
        <a-col :span="8" :offset="4" style="margin-right: 5px" class="space 2st">
          <img src="/image/SilverEgg_250x250_LP.png" alt="SilverEgg_250x250_LP" />
        </a-col>
        <a-col :span="8" class="space 3st">
          <img src="/image/BronzeEgg_250x250_LP.png" alt="BronzeEgg_250x250_LP" />
        </a-col>
      </a-row>
      <h2 class="padding-top-12 ma-0">{{ $t('played.description2') }}</h2>
      <a-row justify="center padding-bottom-12 small-text">
        <a-row :span="24">
          <i>
            {{ $t('played.closingDate') }}
            <a :href="$t('played.closingDateURL')" target="_blank">{{ $t('played.here') }}</a>
          </i>
        </a-row>
      </a-row>
    </div>
  </a-row>
</template>

<script>
export default {
  name: 'Played',
  props: {
    user: Object,
  },
};
</script>

<style lang="less" scoped>
.content {
  max-width: 750px;
}
h2 {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}
.center {
  text-align: center;
  width: 100%;
}
.space {
  height: 250px;
  width: 250px;
  text-align: center;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-12 {
  padding-top: 12px;
}
.ma-0 {
  margin: 0px;
}
.padding-bottom-12 {
  padding-bottom: 12px;
}
.small-text {
  font-size: 14px;
}
</style>
