import { pacmanAPI } from './pacmanApi';

const getUser = (id) => {
  const config = {
    headers: {
      id,
    },
  };
  return pacmanAPI.get('user', config);
};

const startGame = (id) => {
  const options = {};
  const config = {
    headers: {
      id,
    },
  };
  return pacmanAPI.put('start-game', options, config);
};

const setScore = (token, score, recaptcha) => {
  const options = {};
  const config = {
    headers: {
      token,
      score,
      recaptcha,
    },
  };
  return pacmanAPI.put('set-score', options, config);
};

export { startGame, setScore, getUser };
