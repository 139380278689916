<template>
  <main>
    <a-layout class="layout">
      <HeaderMain />
      <ContentMain>
        <slot />
      </ContentMain>
      <footer-main />
    </a-layout>
  </main>
</template>

<script>
import ContentMain from '@/components/layout/ContentMain';
import HeaderMain from '@/components/layout/HeaderMain';
import FooterMain from '@/components/layout/FooterMain';

export default {
  components: {
    FooterMain,
    ContentMain,
    HeaderMain,
  },
  name: 'MainLayouts',
  data: () => ({
    collapsed: false,
  }),
};
</script>

<style scoped></style>
