<template>
  <a-row align="center">
    <div class="content padding-top-12">
      <div>
        <div>{{ $t('notPlayedYet.description1') }}</div>
        <div class="padding-top-12">{{ $t('notPlayedYet.description2') }}</div>
        <h2 class="padding-top-12 ma-0">{{ $t('notPlayedYet.h2Description1') }}</h2>
      </div>
      <a-row class="padding-top-12">
        <a-col :span="8" :offset="8" class="space 1st">
          <img src="/image/GoldEgg_250x250_LP.png" alt="GoldEgg_250x250_LP" />
        </a-col>
      </a-row>
      <a-row class="padding-top-5">
        <a-col :span="8" :offset="4" style="margin-right: 5px" class="space 2st">
          <img src="/image/SilverEgg_250x250_LP.png" alt="SilverEgg_250x250_LP" />
        </a-col>
        <a-col :span="8" class="space 3st">
          <img src="/image/BronzeEgg_250x250_LP.png" alt="BronzeEgg_250x250_LP" />
        </a-col>
      </a-row>
      <h2 class="padding-top-12 ma-0">{{ $t('notPlayedYet.howItWorks') }}</h2>
      <div class="padding-top-12">
        <p class="ma-0">
          {{ $t('notPlayedYet.description3') }}
        </p>
        <p class="padding-top-12 ma-0">
          <b>{{ $t('notPlayedYet.butWatchOut') }}</b>
          {{ $t('notPlayedYet.description4') }}
        </p>
        <p class="padding-top-12 ma-0">
          {{ $t('notPlayedYet.description5') }}
        </p>
        <p class="padding-top-12 ma-0">
          <b>{{ $t('notPlayedYet.pleaseNote') }}</b>
          {{ $t('notPlayedYet.description6') }}
          <b>{{ $t('notPlayedYet.onlyOnce') }}</b>
          {{ $t('notPlayedYet.description7') }}
        </p>
      </div>
      <div class="padding-top-12">
        <a-checkbox @change="changeAggree">
          {{ $t('notPlayedYet.aggreePolicy') }}
          <a :href="$t('notPlayedYet.privacyPolicyURL')" target="_blank">{{ $t('notPlayedYet.privacyPolicy') }}</a> *
        </a-checkbox>
        <div class="warning" v-if="aggree === false">{{ $t('notPlayedYet.requiredPolicy') }}</div>
      </div>
      <a-row class="padding-top-12">
        <a-col :span="12" :offset="6" align="center">
          <a-button class="btn-start-game ma-0" type="primary" @click="startGame">
            {{ $t('notPlayedYet.startGame') }}
          </a-button>
        </a-col>
      </a-row>
      <h2 class="padding-top-12 ma-0">
        {{ $t('notPlayedYet.goodLuck') }} <br />
        {{ $t('notPlayedYet.yourTeam') }}
      </h2>
      <div>
        <b>{{ $t('notPlayedYet.gameOnlyDesktop') }}</b>
        <br />
        <a-row align="center text-small">
          <a-row :span="24">
            <i>
              {{ $t('notPlayedYet.closingDate') }}
              <a :href="$t('notPlayedYet.closingDateURL')" target="_blank">{{ $t('notPlayedYet.here') }}</a>
            </i>
          </a-row>
        </a-row>
      </div>
    </div>
  </a-row>
</template>

<script>
export default {
  name: 'NotPlayedYet',
  props: {
    id: String,
  },
  methods: {
    async startGame() {
      if (this.aggree) {
        this.$router.push('Game');
      } else {
        this.aggree = false;
      }
    },
    changeAggree(val) {
      this.aggree = val.target.checked;
    },
  },
  data() {
    return {
      aggree: null,
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  max-width: 750px;
}
h2 {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}
.space {
  width: 250px;
  height: 250px;
  text-align: center;
}
.padding-top-5 {
  padding-top: 5px;
}
.aggree {
  margin-top: 15px;
}
.btn-start-game {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  height: 45px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
}
.warning {
  color: red;
}
.text-small {
  font-size: 14px;
}
.padding-top-12 {
  padding-top: 12px;
}
.ma-0 {
  margin: 0px;
}
</style>
