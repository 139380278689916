import { createI18n } from 'vue-i18n';
import locales from './locales';
import { store } from '@/store';

const { language } = store.state.app;

const config = {
  locale: language,
  fallbackLocale: 'en',
  messages: locales,
  silentTranslationWarn: true,
};
// eslint-disable-next-line new-cap
const i18n = new createI18n(config);

export { i18n };
