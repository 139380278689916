<template>
  <div class="pageGame" id="game">
    <a-spin :spinning="loading">
      <a-row :style="{ 'min-width': `${minWidth}px` }" align="middle">
        <a-col :xxl="{ span: 4, offset: 2 }" :xl="{ span: 5 }">
          <game-introduction />
        </a-col>
        <a-col :xxl="{ span: 12 }" :xl="{ span: 14 }">
          <div class="game">
            <canvas id="canvas" width="300" height="300">Game</canvas>
          </div>
        </a-col>
        <a-col :xxl="{ span: 4 }" :xl="{ span: 5 }" v-if="form.score > 0 && indexGame < maxGame">
          <result-board :time-out="form.timeOut" :life="form.life" :score="form.score" />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { Game } from '@/lib/game';
import { formatObject } from '@/lib/formatObject';
import { loadImage } from '@/utils/image';
import { NODE_ENV } from '@/../config';
import { configGame } from '@/enum/configGame.enum';
import GameIntroduction from '@/components/gamePage/GameIntroduction';
import ResultBoard from '@/components/gamePage/ResultBoard';
import { setScore, startGame } from '@/service/game.servive';
import { delay } from '@/lib/delay';
import { mapGetters } from 'vuex';
import { useReCaptcha } from 'vue-recaptcha-v3';

export default {
  name: 'Game',
  components: {
    GameIntroduction,
    ResultBoard,
  },
  data() {
    const formDefault = {
      life: 3,
      score: 0,
      // 60p
      timeOut: 60 * 60,
    };
    return {
      game: undefined,
      config: configGame,
      formDefault: formatObject(formDefault),
      form: formatObject(formDefault),
      loading: false,
      token: '',
      executeRecaptcha: undefined,
      recaptchaLoaded: undefined,
      minWidth: 1280,
    };
  },
  computed: {
    ...mapGetters('auth', {
      getUserId: 'getUserId',
    }),
    indexGame() {
      if (!this.game) return 0;
      return this.game.index;
    },
    maxGame() {
      if (!this.game) return 0;
      return this.game.stages.length - 1;
    },
  },
  async created() {
    this.loading = true;
    window.scrollTo(0, 0);
    try {
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
      this.executeRecaptcha = executeRecaptcha;
      this.recaptchaLoaded = recaptchaLoaded;
      await this.setupImage();
    } catch (error) {
      console.log('error', error);
    }
    this.loading = false;
  },
  async mounted() {
    await this.setupWindowResize();
  },
  methods: {
    setupAll() {
      const { ratio } = this.config;
      const width = 1200 * ratio;
      const height = 1360 * ratio;
      this.game = new Game('canvas', { width, height }, this);
      this.setupStartPage();
      this.setupMap();
      this.setupEndPage();
      this.game.init();
    },
    async setupImage() {
      const promises = this.config.images.map((item) => loadImage(item.path));
      const data = await Promise.all(promises);
      data.map((image, index) => {
        const { key } = this.config.images[index];
        this.config.loadImages[key] = image;
      });
    },
    setupStartPage() {
      const stage = this.game.createStage({}, this.game, this);
      stage.createItem(
        {
          x: this.game.width / 2,
          y: this.game.height * 0.45,
          width: 100,
          height: 100,
          frames: 3,
          draw(context) {
            const { loadImages } = this.page.config;
            if (loadImages.logoBr24)
              context.drawImage(
                loadImages.logoBr24,
                0,
                0,
                loadImages.logoBr24.width,
                loadImages.logoBr24.height,
                this.x - 60,
                this.y - 40,
                loadImages.logoBr24.width,
                loadImages.logoBr24.height,
              );
          },
        },
        this.game,
        this,
      );
      stage.createItem(
        {
          x: this.game.width - 12,
          y: this.game.height - 5,
          draw(context) {
            context.font = '14px Helvetica';
            context.textAlign = 'right';
            context.textBaseline = 'bottom';
            context.fillStyle = '#AAA';
            context.fillText('© https://br24.com', this.x, this.y);
          },
        },
        this.game,
        this,
      );
      stage.bind('keydown', async (e) => {
        switch (e.keyCode) {
          case 13:
          case 32:
            e.preventDefault();
            document.getElementById('game').scrollIntoView();
            await this.processStartGame();
            this.countDown();
            this.game.nextStage();
            break;
        }
      });
    },
    setupMap() {
      for (let index = 0; index < 10; index += 1) {
        const config = this.config.coigig[0];
        let map;
        let beans;
        let items;
        let player;
        const stages = this.game.createStage(
          {
            update() {
              const { game } = this.page;
              const { ratio } = this.page.config;
              const stage = this;
              if (stage.status === 1) {
                items.forEach((item) => {
                  if (map && !map.get(item.coord.x, item.coord.y) && !map.get(player.coord.x, player.coord.y)) {
                    const dx = item.x - player.x;
                    const dy = item.y - player.y;
                    const collision = (40 * ratio) ** 2;
                    if (dx * dx + dy * dy < collision && item.status !== 4) {
                      if (item.status === 3) {
                        item.status = 4;
                        this.page.form.score += 10;
                      } else {
                        stage.status = 3;
                        stage.timeout = 30;
                      }
                    }
                  }
                });
                if (JSON.stringify(beans.data).indexOf('0') < 0) {
                  game.nextStage();
                }
              } else if (stage.status === 3) {
                if (!stage.timeout) {
                  this.page.form.life -= 1;
                  if (this.page.form.life) {
                    stage.resetItems();
                  } else {
                    const getStages = game.getStages();
                    game.setStage(getStages.length - 1);
                    return false;
                  }
                }
              }
            },
          },
          this.game,
          this,
        );
        map = stages.createMap(
          {
            x: 0,
            y: 0,
            size: 40 * this.config.ratio,
            data: config.map,
            cache: true,
            draw(context) {
              const { cos, sin, ratio, loadImages, showBorder } = this.page.config;
              if (loadImages.spielfeld)
                this.game.context.drawImage(
                  loadImages.spielfeld,
                  0,
                  0,
                  loadImages.spielfeld.width * ratio,
                  loadImages.spielfeld.height * ratio,
                );
              context.lineWidth = 2;
              for (let j = 0; j < this.yLength; j++) {
                for (let i = 0; i < this.xLength; i++) {
                  const value = this.get(i, j);
                  if (value) {
                    const code = [0, 0, 0, 0];
                    if (
                      this.get(i + 1, j) &&
                      !(this.get(i + 1, j - 1) && this.get(i + 1, j + 1) && this.get(i, j - 1) && this.get(i, j + 1))
                    ) {
                      code[0] = 1;
                    }
                    if (
                      this.get(i, j + 1) &&
                      !(this.get(i - 1, j + 1) && this.get(i + 1, j + 1) && this.get(i - 1, j) && this.get(i + 1, j))
                    ) {
                      code[1] = 1;
                    }
                    if (
                      this.get(i - 1, j) &&
                      !(this.get(i - 1, j - 1) && this.get(i - 1, j + 1) && this.get(i, j - 1) && this.get(i, j + 1))
                    ) {
                      code[2] = 1;
                    }
                    if (
                      this.get(i, j - 1) &&
                      !(this.get(i - 1, j - 1) && this.get(i + 1, j - 1) && this.get(i - 1, j) && this.get(i + 1, j))
                    ) {
                      code[3] = 1;
                    }
                    if (showBorder && code.indexOf(1) > -1) {
                      context.strokeStyle = value === 2 ? '#FFF' : config.wall_color;
                      const pos = this.coord2position(i, j);
                      const dist = this.size / 2;
                      switch (code.join('')) {
                        case '1100':
                          context.beginPath();
                          context.arc(
                            pos.x + this.size / 2,
                            pos.y + this.size / 2,
                            this.size / 2,
                            Math.PI,
                            1.5 * Math.PI,
                            false,
                          );
                          context.stroke();
                          context.closePath();
                          break;
                        case '0110':
                          context.beginPath();
                          context.arc(
                            pos.x - this.size / 2,
                            pos.y + this.size / 2,
                            this.size / 2,
                            1.5 * Math.PI,
                            2 * Math.PI,
                            false,
                          );
                          context.stroke();
                          context.closePath();
                          break;
                        case '0011':
                          context.beginPath();
                          context.arc(
                            pos.x - this.size / 2,
                            pos.y - this.size / 2,
                            this.size / 2,
                            0,
                            0.5 * Math.PI,
                            false,
                          );
                          context.stroke();
                          context.closePath();
                          break;
                        case '1001':
                          context.beginPath();
                          context.arc(
                            pos.x + this.size / 2,
                            pos.y - this.size / 2,
                            this.size / 2,
                            0.5 * Math.PI,
                            1 * Math.PI,
                            false,
                          );
                          context.stroke();
                          context.closePath();
                          break;
                        default:
                          code.forEach((v, indexCode) => {
                            if (v) {
                              context.beginPath();
                              context.moveTo(pos.x, pos.y);
                              context.lineTo(pos.x - cos[indexCode] * dist, pos.y - sin[indexCode] * dist);
                              context.stroke();
                              context.closePath();
                            }
                          });
                      }
                    }
                  }
                }
              }
            },
          },
          this.game,
          this,
        );
        beans = stages.createMap(
          {
            x: 0,
            y: 0,
            size: 40 * this.config.ratio,
            data: config.map,
            frames: 8,
            draw(context) {
              for (let j = 0; j < this.yLength; j++) {
                for (let i = 0; i < this.xLength; i++) {
                  if (!this.get(i, j)) {
                    const pos = this.coord2position(i, j);
                    context.fillStyle = '#F5F5DC';
                    if (config.goods[`${i},${j}`]) {
                      const t = this.times % 2;
                      const imageId = (i + j) % 4;
                      const { loadImages, ratio } = this.page.config;
                      if (loadImages[`big_egg_0${imageId + 1}`]) {
                        const w = 22 * ratio;
                        const h = 28 * ratio;
                        context.save();
                        context.translate(pos.x - w / 2, pos.y - h / 2);
                        context.drawImage(loadImages[`big_egg_0${imageId + 1}`], t * 22, 0, 22, 28, 0, 0, w, h);
                        context.restore();
                      }
                    } else {
                      const imageId = (i + j) % 4;
                      const { loadImages, ratio } = this.page.config;
                      if (loadImages[`egg_0${imageId + 1}`]) {
                        const w = 11 * ratio;
                        const h = 14 * ratio;
                        context.save();
                        context.translate(pos.x - w / 2, pos.y - h / 2);
                        context.drawImage(loadImages[`egg_0${imageId + 1}`], 0, 0, 11, 14, 0, 0, w, h);
                        context.restore();
                      }
                    }
                  }
                }
              }
            },
          },
          this.game,
          this,
        );

        for (let i = 0; i < 4; i++) {
          stages.createItem(
            {
              width: 60 * this.config.ratio,
              height: 60 * this.config.ratio,
              orientation: 3,
              color: `fox_0${i + 1}`,
              location: map,
              // Tọa độ ban đầu.
              coord: { x: 13 + i, y: 15 + (i % 2) },
              vector: { x: 13 + i, y: 15 + (i % 2) },
              type: 2,
              frames: 10,
              speed: 1,
              timeout: Math.floor(Math.random() * 120),
              // eslint-disable-next-line no-loop-func
              update() {
                const { cos, sin } = this.page.config;
                let newMap;
                if (this.status === 3 && !this.timeout) {
                  this.status = 1;
                }
                if (!this.coord.offset) {
                  if (this.status === 1) {
                    if (!this.timeout) {
                      newMap = JSON.parse(JSON.stringify(map.data).replace(/2/g, 0));
                      items.forEach((item) => {
                        if (item.id !== this.id && item.status === 1) {
                          newMap[item.coord.y][item.coord.x] = 1;
                        }
                      });
                      this.path = map.finder({
                        map: newMap,
                        start: this.coord,
                        end: player.coord,
                      });
                      if (this.path.length) {
                        this.vector = this.path[0];
                      }
                    }
                  } else if (this.status === 3) {
                    newMap = JSON.parse(JSON.stringify(map.data).replace(/2/g, '0'));
                    const id = this.id;
                    items.forEach((item) => {
                      if (item.id !== id) {
                        newMap[item.coord.y][item.coord.x] = 1;
                      }
                    });
                    this.path = map.finder({
                      map: newMap,
                      start: player.coord,
                      end: this.coord,
                      type: 'next',
                    });
                    if (this.path.length) {
                      this.vector = this.path[Math.floor(Math.random() * this.path.length)];
                    }
                  } else if (this.status === 4) {
                    newMap = JSON.parse(JSON.stringify(map.data).replace(/2/g, '0'));
                    this.path = map.finder({
                      map: newMap,
                      start: this.coord,
                      end: this.params.coord,
                    });
                    if (this.path.length) {
                      this.vector = this.path[0];
                    } else {
                      this.status = 1;
                    }
                  }
                  if (this.vector.change) {
                    this.coord.x = this.vector.x;
                    this.coord.y = this.vector.y;
                    const pos = map.coord2position(this.coord.x, this.coord.y);
                    this.x = pos.x;
                    this.y = pos.y;
                  }
                  if (this.vector.x > this.coord.x) {
                    this.orientation = 0;
                  } else if (this.vector.x < this.coord.x) {
                    this.orientation = 2;
                  } else if (this.vector.y > this.coord.y) {
                    this.orientation = 1;
                  } else if (this.vector.y < this.coord.y) {
                    this.orientation = 3;
                  }
                }
                this.x += this.speed * cos[this.orientation];
                this.y += this.speed * sin[this.orientation];
              },
              draw(context) {
                const { cos, sin } = this.page.config;
                let isSick = false;
                if (this.status === 3) {
                  isSick = !!(this.timeout > 80 || this.times % 2);
                }
                if (this.status !== 4) {
                  const t = this.times % 2;
                  const { loadImages, ratio } = this.page.config;
                  const size = 40 * ratio;
                  const center = size / 2;
                  // ảnh nhân vật NPC
                  if (loadImages[this.color]) {
                    context.save();
                    context.translate(this.x + center, this.y - center);
                    context.scale(1, -1);
                    context.rotate((180 * Math.PI) / 180);
                    if (this.status === 3 && isSick) {
                      context.drawImage(loadImages.fox_05, t * 60, 0, 60, 60, 0, 0, size, size);
                    } else {
                      context.drawImage(loadImages[this.color], t * 60, 0, 60, 60, 0, 0, size, size);
                    }
                    context.restore();
                  }
                  context.fill();
                  context.closePath();
                }
                context.fillStyle = '#FFF';
                if (this.status === 4 && !isSick) {
                  context.beginPath();
                  context.arc(
                    this.x - this.width * 0.15,
                    this.y - this.height * 0.21,
                    this.width * 0.12,
                    0,
                    2 * Math.PI,
                    false,
                  );
                  context.arc(
                    this.x + this.width * 0.15,
                    this.y - this.height * 0.21,
                    this.width * 0.12,
                    0,
                    2 * Math.PI,
                    false,
                  );
                  context.fill();
                  context.closePath();
                  context.fillStyle = '#000';
                  context.beginPath();
                  context.arc(
                    this.x - this.width * (0.15 - 0.04 * cos[this.orientation]),
                    this.y - this.height * (0.21 - 0.04 * sin[this.orientation]),
                    this.width * 0.07,
                    0,
                    2 * Math.PI,
                    false,
                  );
                  context.arc(
                    this.x + this.width * (0.15 + 0.04 * cos[this.orientation]),
                    this.y - this.height * (0.21 - 0.04 * sin[this.orientation]),
                    this.width * 0.07,
                    0,
                    2 * Math.PI,
                    false,
                  );
                  context.fill();
                  context.closePath();
                }
              },
            },
            this.game,
            this,
          );
        }
        items = stages.getItemsByType(2);
        player = stages.createItem(
          {
            width: 60 * this.config.ratio,
            height: 60 * this.config.ratio,
            type: 1,
            location: map,
            coord: { x: 13, y: 25 },
            orientation: 2,
            speed: 2,
            frames: 10,
            update() {
              const { cos, sin } = this.page.config;
              const coord = this.coord;
              if (!coord.offset) {
                if (typeof this.control.orientation !== 'undefined') {
                  if (!map.get(coord.x + cos[this.control.orientation], coord.y + sin[this.control.orientation])) {
                    this.orientation = this.control.orientation;
                  }
                }
                this.control = {};
                const value = map.get(coord.x + cos[this.orientation], coord.y + sin[this.orientation]);
                if (value === 0) {
                  this.x += this.speed * cos[this.orientation];
                  this.y += this.speed * sin[this.orientation];
                } else if (value < 0) {
                  this.x -= map.size * (map.xLength - 1) * cos[this.orientation];
                  this.y -= map.size * (map.yLength - 1) * sin[this.orientation];
                }
              } else {
                if (!beans.get(this.coord.x, this.coord.y)) {
                  this.page.form.score++;
                  beans.set(this.coord.x, this.coord.y, 1);
                  if (config.goods[`${this.coord.x},${this.coord.y}`]) {
                    items.forEach((item) => {
                      if (item.status === 1 || item.status === 3) {
                        item.timeout = 450;
                        item.status = 3;
                      }
                    });
                  }
                }
                this.x += this.speed * cos[this.orientation];
                this.y += this.speed * sin[this.orientation];
              }
            },
            draw(context) {
              context.fillStyle = '#FFE600';
              context.beginPath();
              const { loadImages, ratio } = this.page.config;
              const size = 40 * ratio;
              const center = size / 2;
              const t = this.times % 2;
              if (loadImages.rabbit) {
                context.save();
                switch (this.orientation) {
                  case 0:
                    // right
                    context.translate(this.x - center, this.y - center);
                    break;
                  case 1:
                    // dow
                    context.translate(this.x + center, this.y - center);
                    context.rotate((90 * Math.PI) / 180);
                    break;
                  case 2:
                    // left
                    context.translate(this.x + center, this.y - center);
                    context.scale(1, -1);
                    context.rotate((180 * Math.PI) / 180);
                    break;
                  case 3:
                    // up
                    context.translate(this.x - center, this.y + center);
                    context.scale(-1, -1);
                    context.rotate((90 * Math.PI) / 180);
                    break;
                }
                if (stages.status !== 3) {
                  context.drawImage(loadImages.rabbit, t * 60, 0, 60, 60, 0, 0, size, size);
                } else if (stages.timeout) {
                  if (stages.timeout % 2) context.drawImage(loadImages.rabbit, t * 60, 0, 60, 60, 0, 0, size, size);
                }
                context.restore();
              }
              context.lineTo(this.x, this.y);
              context.closePath();
              context.fill();
            },
          },
          this.game,
          this,
        );
        stages.bind('keydown', function process(e) {
          switch (e.keyCode) {
            case 13:
            case 32:
              e.preventDefault();
              break;
            case 39:
              player.control = { orientation: 0 };
              e.preventDefault();
              break;
            case 40:
              player.control = { orientation: 1 };
              e.preventDefault();
              break;
            case 37:
              player.control = { orientation: 2 };
              e.preventDefault();
              break;
            case 38:
              player.control = { orientation: 3 };
              e.preventDefault();
              break;
          }
        });
      }
    },
    setupEndPage() {
      const stage = this.game.createStage({}, this.game, this);
      stage.createItem(
        {
          x: this.game.width / 2,
          y: this.game.height * 0.35,
          draw(context) {
            context.fillStyle = '#FFF';
            context.font = 'bold 48px Helvetica';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            const text = this.$t('game.youWin');
            context.fillText(text, this.x, this.y);
          },
        },
        this.game,
        this,
      );
      stage.createItem(
        {
          x: this.game.width / 2,
          y: this.game.height * 0.5,
          draw(context) {
            const { life, score } = this.page.form;
            context.fillStyle = '#FFF';
            context.font = '20px Helvetica';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            const finalScore = score + 50 * Math.max(life - 1, 0);
            const text = this.page.$t('game.yourPoints', { score: finalScore });
            context.fillText(text, this.x, this.y);
          },
        },
        this.game,
        this,
      );
      stage.bind('keydown', (e) => {
        switch (e.keyCode) {
          case 13:
          case 32:
            if (NODE_ENV !== 'production') {
              this.form = formatObject(this.formDefault);
              this.game.setStage(1);
            }
            e.preventDefault();
            break;
        }
      });
    },
    async recaptcha() {
      await this.recaptchaLoaded();
      const recaptcha = await this.executeRecaptcha('setScore');
      return { recaptcha };
    },
    async processStartGame(count = 0) {
      this.loading = true;
      try {
        const { token } = await startGame(this.getUserId);
        this.token = token;
      } catch (e) {
        if (e && e.response && e.response.data) {
          const { message } = e.response.data;
          if (message === 'User has played!') return this.$router.push({ name: 'LandingPage' });
        }
        if (count > 3) throw e;
        await delay(2000);
        await this.processStartGame((count += 1));
      }
      this.loading = false;
    },
    async processSetScore(count = 0) {
      this.loading = true;
      try {
        const { score, life } = this.form;
        const finalScore = score + 50 * Math.max(life - 1, 0);
        const { recaptcha } = await this.recaptcha();
        await setScore(this.token, finalScore, recaptcha);
        await this.$router.push({ name: 'LandingPage' });
      } catch (e) {
        if (count > 1000) throw e;
        await delay(2000);
        await this.processSetScore((count += 1));
      }
      this.loading = false;
    },
    async countDown() {
      if (this.form.timeOut > 0) {
        this.form.timeOut -= 1;
        await delay(1000);
        await this.countDown();
      } else {
        const getStages = this.game.getStages();
        this.game.setStage(getStages.length - 1);
      }
    },
    async setupWindowResize() {
      const { innerHeight } = window;
      let ratio = innerHeight / 1360;
      if (ratio < 0.5) ratio = 0.5;
      if (ratio > 1) ratio = 1;
      this.config.ratio = Math.floor(ratio * 10) / 10;
      this.minWidth = ratio * 1200 + (30 + 260 + 10) * 2;
      this.setupAll();
    },
  },
  watch: {
    async indexGame(value) {
      if (value && value === this.maxGame) {
        await this.processSetScore();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pageGame {
  overflow-x: auto;
  padding: 10px;
}
.game {
  text-align: center;
}
</style>
